<template lang="html">
  <section class="page-new container categorias column-start" v-if="entry">
    <h2 class="subtitulo-documentos row-center">
      <span></span>NOTICIAS<span></span>
    </h2>
    <section class="newsletter-detail">
      <img class="main-img" :src="entry.header_img.url" alt="" />
      <p class="fecha">{{currentDateTime(entry.order_date)}}</p>
      <h2>{{ entry.title }}</h2>
      <h3 class="entradilla">{{entry.header}}</h3>
      <EditorJs :object="entry.description" />
      <router-link :to="{ name: 'news'}" class="volver"><span>Volver</span></router-link>
    </section>
  </section>
</template>

<script lang="js">
  import { mapGetters, mapActions } from 'vuex';
  import EditorJs from "@/components/editorJs.vue";
  import store from '@/store';
  import moment from 'moment'
  export default  {
    name: 'page-new',
    props: ['slug'],
    components:{EditorJs},
    data () {
      return {
      }
    },
    computed: {
    //Computed -> Te permite crear datas que sean funciones y sean dinámicas para cambiar el data https://vuejs.org/v2/guide/computed.html
    //Se puede llamar asi
    // ...mapGetters({
    //     getNewFuntion : 'getNew'
    // }),
    // new(){
    //   return this.getNewFuntion(this.slug)
    // }
    //o así
    entry: function() {
      return store.getters['contents/getNew'](this.slug)
    }
    },
    methods: {
      ...mapActions({
        loadNew : 'contents/loadNew'
      }),
      currentDateTime(date) {
          return moment(date).locale('es').format('L')
      }
    },
  
    mounted () {
       this.loadNew({slug : this.slug})
    },
}
</script>

<style scoped>
.page-new {
}
</style>
